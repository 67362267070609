import * as React from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import "./Appointment.css";
import AddIcon from "@mui/icons-material/Add";
import AppointmentCard from "../../components/therapist/AppointmentCard";
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
// import Modal from '@mui/material/Modal';
// import TextField from '@mui/material/TextField';
import { useModal, Modal } from "react-morphing-modal";
import "react-morphing-modal/dist/ReactMorphingModal.css";
import { useState, useEffect } from "react";
import { BASE_URL } from "../../apiConfig";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#fff",
  border: "2px solid #f5f5f7",
  boxShadow: 24,
  p: 4,
};

export default function TherapistAppointment() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { modalProps, getTriggerProps } = useModal({
    background: "#21252994;",
  });

  // const data = [{name: 'Page A', uv: 400, pv: 2400, amt: 2400}];

  const [slotPastData, setSlotPastData] = useState([]);
  const [slotUpcomingData, setSlotUpcomingData] = useState([]);

  useEffect(() => {
    fetch(`${BASE_URL}/slot/bookedSlots`, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setSlotPastData(data.pastSlots);
        setSlotUpcomingData(data.upcomingSlots);
      })
      .catch((error) => {
        console.error("Error fetching slot data:", error);
      });
  }, []);

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
        padding: " 0 10px",
      }}
    >
      <Toolbar />
      <Container
        maxWidth="xl"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          mt: 4,
          mb: 4,
        }}
      >
        <div>
          <h5 style={{ fontWeight: "600" }}>Upcoming appointments </h5>
          <p>All upcoming appointments</p>
        </div>
        {/* <button {...getTriggerProps()} className="bookAppointmentBtn">
          Add <span>an Appointment</span> <AddIcon />
        </button> */}

        <Modal {...modalProps}>i am A MODAL</Modal>
      </Container>
      <Container
        maxWidth="md"
        sx={{
          mt: 4,
          mb: 4,
          backgroundColor: "#fff",
          borderRadius: "10px",
          padding: "25px 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: "30px 0",
        }}
      >
        {slotUpcomingData.length <= 0 && (
          <p style={{ marginBottm: 0 }}>No upcoming appointments!</p>
        )}

        {slotUpcomingData?.map((slot, index) => (
          <AppointmentCard status="upcoming" type="patient" data={slot} /> //for "patient" coz card will show patient details
        ))}
      </Container>
      <Container
        maxWidth="xl"
        sx={{
          mt: 4,
          mb: 4,
        }}
      >
        <div>
          <h5 style={{ fontWeight: "600" }}>Past appointments </h5>
          <p>All past appointments</p>
        </div>
      </Container>
      <Container
        maxWidth="md"
        sx={{
          mt: 4,
          mb: 4,
          backgroundColor: "#fff",
          borderRadius: "10px",
          // padding: "25px 10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: "30px 0",
        }}
        style={{ padding: "25px 10px" }}
      >
        {slotPastData.length <= 0 && (
          <p style={{ marginBottm: 0 }}>No appointments yet!</p>
        )}
        {slotPastData?.map((slot, index) => (
          <AppointmentCard status="past" type="patient" data={slot} />
        ))}
      </Container>
    </Box>
  );
}
